@tailwind base;
@tailwind components;
@tailwind utilities;

/* Font Import */
@font-face {
  font-family: 'BaseNeueSuperExpanded';
  src: url('../fonts/BaseNeue-Expanded.ttf') format('truetype');
  /* Adjust the path to your font file */
  font-weight: normal;
  font-style: normal;
}

/* Font Import */
@font-face {
  font-family: 'OrbitronRegular';
  src: url('../fonts/Orbitron-Regular.ttf') format('truetype');
  /* Adjust the path to your font file */
  font-weight: normal;
  font-style: normal;
}

/* ------------------------ NAVBAR ----------------------- */
.navbar-menu a {
  font-family: 'BaseNeueSuperExpanded', sans-serif;
  /* Ensure the font is applied to menu items */
  font-size: 15px;
}

.navbar-menu a:hover {
  color: #5281FF;
  /* Change color on hover */
}

.icon {
  color: white;
  /* Icon default color */
}

.icon:hover {
  color: #ccc;
  /* Icon color on hover */
}

.navbar-icons {
  display: flex;
  align-items: center;
  justify-content: between;
  /* Added as per your request */
  gap: 20px;
}

/* ------Hero Section ---------*/
.hero-section {
  background-size: cover;
  background-position: center;
  min-height: 800px;
  /* Full viewport height */
  height: auto;
  background-color: black;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  /* Centers content vertically and horizontally */
  padding: 20px;
  box-sizing: border-box;
  position: relative;
}

.hero-section img {
  animation: rotate 20s linear infinite;
  /* 20s duration, linear motion, infinite loop */
}


.book-now-btn {
  display: inline-block;
  padding: 10px 20px;
  font-size: 16px;
  font-family: 'BaseNeueSuperExpanded', sans-serif;
  /* Applying Neue font */
  color: white;
  background-color: #0B0B0B;
  /* Background color for button */
  border-radius: 40px;
  border: 4px solid;
  background-clip: padding-box;
  /* Ensures background doesn't overlap the border */
  position: relative;
  z-index: 1;
}

.book-now-btn-1 {
  display: inline-block;
  padding: 10px 20px;
  font-size: 16px;
  font-family: 'BaseNeueSuperExpanded', sans-serif;
  /* Applying Neue font */
  color: white;
  background-color: #0B0B0B;
  /* Background color for button */
  border-radius: 40px;
  border: 4px solid;
  background-clip: padding-box;
  /* Ensures background doesn't overlap the border */
  position: relative;
  z-index: 1;
}

.book-now-btn::before,
.book-now-btn-1::before {
  content: "";
  position: absolute;
  top: -4px;
  left: -4px;
  right: -4px;
  bottom: -4px;
  border-radius: 30px;
  background: linear-gradient(90deg, #FF875E, #7684F1, #5281FF, #66FFD6, #FFD066);
  z-index: -1;
  background-size: 300%;
  animation: gradientAnimation 3s infinite linear;
}


@keyframes gradientAnimation {
  0% {
    background-position: 0% 50%;
  }

  100% {
    background-position: 100% 50%;
  }
}

/* Hero Section Title */
.hero-title {
  font-family: 'BaseNeueSuperExpanded', sans-serif;
  /* Custom font */
  font-weight: 400;
  font-size: 48px;
  line-height: 1.2;
  text-align: center;
  color: white;
  margin: 0 0 20px 0;
  /* Adjust space above and below the title */
  padding: 0;
  width: 100%;
  max-width: 1200px;
  /* Ensure it doesn’t stretch too much on larger screens */
  word-wrap: break-word;
  /* Prevents words from overflowing */
  z-index: 2;
}

/* Gradient for the word 'Complex' */
.hero-title .highlight-complex {
  background: linear-gradient(90deg, #FF875E, #7684F1, #5281FF, #66FFD6, #FFD066);
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
  /* Hides the original text color */
}

.highlight-complex {
  opacity: 0;
  /* Initially hidden */
  transform: translateY(20px);
  /* Start slightly below */
  transition: opacity 2s ease-out, transform 2s ease-out;
}


.hero-title .highlight-problems {
  color: white;
}

.hero-paragraph {
  font-family: 'BaseNeueSuperExpanded', sans-serif;
  /* Using your custom font */
  text-align: center;
  color: #fff;
  font-size: 14px;
  line-height: 1.6;
  max-width: 600px;
  margin-top: 20px;
  margin-top: -10px;
  /* Move the paragraph upwards */
  z-index: 3;
}


/* ABOUT SECTION STYLES */

.about-section {
  position: relative;
  /* Set this to relative so child elements can be absolutely positioned */
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
  /* Enable parallax effect */
  height: 800px;
  z-index: 1;
  /* Lowest z-index to be the bottom layer */
  background-color: black;
}

.corner-image {
  position: absolute;
  bottom: 0;
  right: 0;
  width: auto;
  /* Adjust the size as needed */
  height: 650px;
  z-index: 2;
  /* Middle layer */
}

.about-title {
  position: absolute;
  top: 15%;
  /* Adjust the vertical positioning */
  left: 50px;
  /* Adjust left position as needed */
  transform: translateY(-50%);
  /* Adjust for centering */
  font-family: 'OrbitronRegular', sans-serif;
  /* Use the imported font */
  font-size: 38px;
  /* Adjust font size as needed */
  font-weight: bold;
  color: white;
  /* Ensure the title color is visible */
  z-index: 3;
  /* Ensure this is above both the section and the image */
  opacity: 0;
  /* Initially hidden */
  transition: opacity 1s ease-out, transform 1s ease-out;
}

.service-card {
  background-color: #171717;
  padding: 20px;
  width: 270px;
  /* Ancho de la card */
  border-radius: 15px;
  width: 300px;
  color: white;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.3);
  text-align: left;
  /* Align content to the left */
  top: 20px;
  margin: 0 10px;
  /* Horizontal space between the cards */
  opacity: 0;
  transform: translateY(50px);
  /* Cards start slightly below */
  transition: opacity 0.8s ease-out, transform 0.8s ease-out;
  /* Smooth transition */
}

/* When the card becomes visible, apply this class */
.service-card.visible {
  opacity: 1;
  /* Fully visible */
  transform: translateY(0);
  /* Moves to the original position */
}

/* Add a slight delay for each card to create the staggered effect */
.service-card:nth-child(1) {
  transition-delay: 0.1s;
}

.service-card:nth-child(2) {
  transition-delay: 0.3s;
}

.service-card:nth-child(3) {
  transition-delay: 0.5s;
}


.service-title {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 15px;
  font-family: 'OrbitronRegular', sans-serif;
  text-align: left;
  /* Ensure the title is aligned to the left */
}

.service-description {
  font-size: 12px;
  line-height: 1.5;
  margin-bottom: 20px;
  font-family: 'BaseNeueSuperExpanded', sans-serif;
  text-align: left;
  /* Ensure the paragraph is aligned to the left */
}

.service-line {
  width: 100%;
  height: 0.5px;
  background-color: white;
}

.cards-wrapper {
  display: flex;
  /* Place cards in a row */
  justify-content: left;
  /* Align the cards to the left */
  gap: 15px;
  /* Space between cards */
  flex-wrap: wrap;
  /* Allow wrapping if cards don’t fit in a row */
  z-index: 3;
  position: relative;
  /* Position relative to allow independent movement */
  top: 250px;
  /* Move the cards down */
  padding-left: 40px;
  /* Move the cards to the left */
}

/* Buttons container */
.buttons-container {
  display: flex;
  justify-content: left;
  /* Align buttons to center */
  gap: 20px;
  /* Space between the buttons */
  position: relative;
  top: 350px;
  padding-left: 50px;
  z-index: 4;
}

.contact-us-btn {
  display: inline-block;
  padding: 10px 20px;
  font-size: 16px;
  font-family: 'BaseNeueSuperExpanded', sans-serif;
  /* Applying Neue font */
  color: black;
  background-color: white;
  /* Background color for button */
  border-radius: 40px;
  border: 2px solid;
  background-clip: padding-box;
  /* Ensures background doesn't overlap the border */
  opacity: 0;
  /* Initially hidden */
  transform: translateY(20px);
  /* Positioned slightly below */
  transition: opacity 1s ease-out, transform 1s ease-out, background-color 0.3s ease-in-out;
}

.contact-us-btn:hover {
  color: white;
  /* Change text color to white */
  border-color: white;
  /* Remove solid border color on hover */
  background-color: transparent;
  border: 2px solid;
}


.projects-section {
  background-size: cover;
  /* Ensures the image covers the entire section */
  background-position: center;
  min-height: 500px;
  /* Full viewport height */
  background-color: #111010;
  animation: moveBackground 50s linear infinite;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 5rem;
  /* Infinite animation */
}

/* Define the keyframes for the background animation */
@keyframes moveBackground {
  0% {
    background-position: 0% 50%;
    /* Start from left */
  }

  100% {
    background-position: 100% 50%;
    /* Move to the right */
  }
}
.projects-title-1 {
  /* Adjust the vertical positioning */
  padding-left: 45px;
  /* Adjust left position as needed */
  transform: translateY(-50%);
  /* Adjust for centering */
  font-family: 'OrbitronRegular', sans-serif;
  /* Use the imported font */
  font-weight: bold;
  font-size: 38px;
  /* Adjust font size as needed */
  color: white;
  /* Ensure the title color is visible */
}

.cards-wrapper-projects {
  display: flex;
  justify-content: center;
  gap: 15px;
  flex-wrap: nowrap;
  z-index: 3;
  position: relative;
  max-width: 975px;
  margin: 0 auto;
}

.cardsBox {
  width: 975px;
  height: 100%;
  display: flex;
  position: relative;
  overflow: hidden;
}

.cardsTrack {
  display: flex;
  transition: transform 0.5s ease-in-out;
}

.cardContent {
  min-width: 200px;
  width: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  margin-left: 65px;
}
.cardContent h4{
  font-size: 30px;
  text-transform: uppercase;
  font-weight: 800;
  cursor: pointer;
  transition: transform 0.3s ease;
}
.noLeft{
  margin-left: 15px !important;
}

.cardContent-img {
  max-height: 200px;
  object-fit: contain;
  transition: transform 0.4s ease;
}
.cardContent-img:hover,
.cardContent h4:hover {
  transform: scale(1.1);
}

.projects-card {
  background-color: transparent;
  padding: 20px;
  width: 200px;
  border-radius: 25px;
  color: white;
  text-align: left;
  top: 20px;
  margin: 0 10px;
  width: 450px;
  height: 285px;
  transition: all 0.3s ease-in-out;
}

.projects-card:hover {
  filter: brightness(1);
  /* Increase brightness on hover */
}

.projects-title {
  font-size: 1.25rem;
  margin-bottom: 0.5rem;
  font-family: 'OrbitronRegular', sans-serif;
  padding-left: 45px;
}

.projects-description {
  color: #4A5568;
  /* A darker gray color */
  margin-bottom: 1rem;
}

.projects-line {
  width: 100%;
  height: 2px;
  background-color: #E2E8F0;
  /* Light gray line */
  margin-top: 1rem;
}


.footer {
  background-color: #0B0B0B;
  padding: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.footer-icons {
  display: flex;
  align-items: center;
  gap: 1rem;
  /* Adds space between the icons */
}

.footer-btn {
  background: none;
  border: none;
  cursor: pointer;
}

.footer-icon {
  color: white;
}

.x-logo {
  width: 25px;
  height: 25px;
}


.x-logo-footer {
  width: 25px;
  height: 25px;
}

.x-logo,
.x-logo-footer:hover {
  filter: grayscale(100%);
  /* Grayscale effect on hover */
}

.button-test {
  position: relative;
  display: inline-block;
  padding: 10px 20px;
  background-color: transparent;
  /* Transparent background */
  font-size: 15px;
  font-family: 'BaseNeueSuperExpanded', sans-serif;
  color: white;
  font-weight: bold;
  border: 3px solid transparent;
  /* Transparent border to apply gradient */
  border-radius: 30px;
  /* Border radius */
  cursor: pointer;
  z-index: 1;
  overflow: hidden;
  background-image: linear-gradient(#0B0B0B, #0B0B0B), linear-gradient(to right, #FF875E, #7684F1, #52B1FF, #66FFD6, #FFD066);
  /* Dual background gradient */
  background-origin: border-box;
  background-clip: padding-box, border-box;
  opacity: 0;
  /* Initially hidden */
  transform: translateY(20px);
  /* Positioned slightly below */
  transition: opacity 1s ease-out, transform 1s ease-out;
}

.button-test::before,
.button-test-1::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(to right, #FF875E, #7684F1, #52B1FF, #66FFD6, #FFD066);
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
  z-index: -1;
  /* Ensure the gradient stays behind the content  */
  border-radius: 3px;
}

.button-test:hover::before,
.button-test-1:hover::before {
  opacity: 1;
}

.button-test-1 {
  position: relative;
  display: inline-block;
  padding: 10px 20px;
  background-color: transparent;
  /* Transparent background */
  font-size: 15px;
  font-family: 'BaseNeueSuperExpanded', sans-serif;
  color: white;
  font-weight: bold;
  border: 3px solid transparent;
  /* Transparent border to apply gradient */
  border-radius: 30px;
  /* Border radius */
  cursor: pointer;
  z-index: 1;
  overflow: hidden;
  background-image: linear-gradient(#0B0B0B, #0B0B0B), linear-gradient(to right, #FF875E, #7684F1, #52B1FF, #66FFD6, #FFD066);
  /* Dual background gradient */
  background-origin: border-box;
  background-clip: padding-box, border-box;
  transition: background-image 1s ease-in-out;
}



.button-test:hover,
.button-test-1:hover {
  background-image: none;
  /* Apply gradient colors from the image */
  color: white;
  /* Keep text white on hover */
}

.fade-in {
  opacity: 1;
  /* Fade in */
  transform: translateY(0);
  /* Return to original position */
}

@keyframes rotate {
  0% {
    transform: rotateX(360deg)
  }

  100% {
    transform: rotateX(0deg)
  }
}


.carousel-controls {
  display: flex;
  justify-content: center;
  margin-top: 250px;
}

.carousel-controls button {
  background: transparent;
  border: none;
  color: white;
  font-size: 2rem;
  cursor: pointer;
  padding: 0 10px;
  transition: transform 0.2s;
}

.carousel-controls button:hover {
  transform: scale(1.2);
}

.page-indicators {
  display: flex;
  position: absolute;
  /* Position it relative to the section */
  top: 140px;
  /* Distance from the top of the section */
  right: 100px;
  /* Distance from the right edge of the section */
  gap: 3px;
  /* Space between each indicator */
  align-items: center;
  /* Center align items vertically */
}

.indicator {
  width: 34px;
  /* Size of the SVG */
  height: 34px;
  transition: background-color 0.3s ease;
  cursor: pointer;
  /* Make it appear as a clickable button */
}


.indicator.active {
  background-color: transparent;
}


/* Alternate the vertical alignment to create the zigzag effect */
.indicator:nth-child(odd) {
  transform: translateY(-17px);
  /* Shift up */
}

.indicator:nth-child(even) {
  transform: translateY(17px);
  /* Shift down */
}

.indicator.active {
  transform: translateY(-17px) scale(1.1);
  /* Active indicator with slight scaling */
}

@keyframes slideIn {
  0% {
    opacity: 0;
    transform: translateX(-100px) scale(0.9);
    /* Add scale effect */
  }

  100% {
    opacity: 1;
    transform: translateX(10) scale(1);
  }
}

.fade-slide {
  animation: slideIn 1s ease-in-out;
  /* Slide in, fade in, and scale up */
  will-change: opacity, transform;
}



.projects-description-image {
  width: 350px;
  height: 300px;
  object-fit: cover;
  /* Asegura que la imagen se adapte al tamaño manteniendo su proporción */
  object-position: center;
  transition: transform 0.4s ease;
  /* Smooth zoom effect on hover */
}


.project-description {
  position: absolute;
  font-family: 'BaseNeueSuperExpanded', sans-serif;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: black;
  /* Semi-transparent background */
  color: white;
  text-align: center;
  padding: 10px;
  width: 400px;
  opacity: 0;
  /* Hidden by default */
  transform: translateY(100%);
  /* Move it off the card initially */
  transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;
}

.projects-card:hover .project-description {
  opacity: 1;
  /* Show the description on hover */
  transform: translateY(0);
  /* Slide it up to its original position */
}

.view-on-x {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 15px;
  cursor: pointer;
}

.x-logo {
  width: 24px;
  /* Set the size of the X logo */
  height: 24px;
  margin-right: 8px;
  /* Add space between the logo and text */
}

.view-on-x-text {
  font-family: 'BaseNeueSuperExpanded', sans-serif;
  /* Applying Neue font */
  font-size: 14px;
  color: white;
  text-align: center;
  transition: color 0.3s ease;
}

.view-on-x:hover .view-on-x-text {
  color: #5281FF;
  /* Change color on hover */
}



/* Media Query for mobile responsiveness */
@media (max-width: 768px) {
  .navbar-menu {
    display: none;
    /* Hide menu items */
  }

  .book-now-btn {
    font-size: 12px;
    /* Smaller font size for mobile */
    padding: 4px 8px;
    /* Adjust button padding */
    margin-left: 0;
    /* Align the button to the left */
    position: relative;
  }

  nav {
    padding: 0 16px;
    /* Reduce padding for mobile view */
  }

  .navbar-icons {
    justify-content: flex-end;
    /* Move icons to the right */
    padding-left: 120px;
  }

  /* Center the logo */
  nav div img {
    position: relative;
    left: 0;
    transform: translateX(0);
  }

  .hero-section {
    height: 800px;
  }

  .hero-title {
    font-size: 35px;
    text-align: center;
    /* Ensure it's centered on mobile */

  }

  .hero-paragraph {
    font-size: 15px;
    text-align: center;
  }

  .about-section {
    height: 1300px;
    /* Adjust height for smaller screens */
    padding: 20px;
    position: relative;
    /* Ensure child elements can be positioned within the section */
    background-size: cover;
    background-position: center;
  }

  .about-title {
    top: 7%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 33px;
    /* Adjust font size for smaller screens */
    text-align: center;
    z-index: 2;
    /* Ensure it stays above the image */
  }

  .corner-image {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 100%;
    /* Adjust width as needed */
    height: auto;
    z-index: 1;
    /* Ensure the image stays below the content */
  }
  .cards-wrapper-projects{
    width: 100%;
  }
  .cards-wrapper {
    display: flex;
    flex-direction: column;
    /* Stack cards vertically */
    justify-content: center;
    align-items: center;
    gap: 20px;
    /* Add spacing between the cards */
    top: 180px;
    /* Move the cards up to overlap the image */
    padding: 0;
    z-index: 3;
    /* Ensure the cards are above the image */
  }

  .service-card {
    width: 90%;
    /* Take most of the width for each card */
    text-align: center;
    background-color: rgba(23, 23, 23, 0.9);
    /* Slight transparency to see the background */
  }

  .service-title {
    text-align: center;
    /* Center title for mobile */
    font-size: 18px;
    /* Adjust font size for smaller screens */
  }

  .service-description {
    text-align: center;
    /* Center description for mobile */
    font-size: 14px;
    /* Adjust font size for smaller screens */
  }

  .service-line {
    margin: 10px auto;
    /* Center the line and add space */
  }

  .buttons-container {
    flex-direction: column;
    /* Stack buttons vertically */
    align-items: center;
    top: 210px;
    /* Move buttons up to overlap the image */
    padding: 0;
    gap: 10px;
    /* Add space between the buttons */
    z-index: 3;
    /* Ensure buttons stay above the image */
  }

  .book-now-btn-1,
  .contact-us-btn {
    width: 90%;
    /* Buttons take most of the width */
    font-size: 14px;
    /* Adjust font size */
  }

  .projects-section {
    background-size: cover;
    /* Ensures the image covers the entire section */
    background-position: center;
    /* Centers the background image */
    /* Full viewport height */
    overflow: hidden;
    animation: moveBackground 50s linear infinite;
    /* Infinite animation */
  }

  .projects-title-1 {
    text-align: center;
    /* Center the text */
    font-size: 33px;
    /* Adjust the font size */
    position: relative;
    /* Ensure it's positioned within its container */
    width: 100%;
    /* Ensures it takes up the full width of the container */
    padding-left: 5px;
    top: 60px;
  }

  .projects-title {
    display: flex;
    justify-content: center;
    /* Horizontal centering */
    align-items: center;
    /* Vertical centering */
    padding-left: 5px;
  }

  .page-indicators {
    bottom: 450px;
    gap: 2px;
    left: 33px;
  }

  .button-test-1 {
    font-size: 10px;
    width: 110px;
    white-space: nowrap;
    /* Prevents text from wrapping */
    text-align: center;
  }

  .button-test {
    width: 90%;
    /* Buttons take most of the width */
    font-size: 14px;
    /* Adjust font size */
  }

  .footer {
    padding-bottom: 30px;
  }

  .project-section,
  .about-section,
  .hero-section {
    margin: 0;
    padding: 0;
  }
  .cardsBox{
    width: 95%;
  }
  .cardContent{
    margin-left: 0px;
  }
  .noLeft{
    margin-left: 0px !important;
  }
  .h-36{
    height: 135px;
    
  }
  .small{
    
    max-width: 150px ;
  }

}
@media(width <= 520px){

  .cardContent {
    min-width: 215px;
    width: auto;
    max-width: 215px;
  }
  .cardContent h4{
    font-size: 25px;
  }
  .small{
    min-width:150px;
    width: 150px;
    max-width: 150px ;
  }
}
@media(width <= 420px){
  .cardsBox{
    width: 85%;
  }
}

@media(width <= 1045px){
  .about-section{
    min-height: 1025px;
  }
}
